import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoginModel } from '../service/LoginModel';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AiloginserviceService {
  public token: string;   
  private LoginDetails: LoginModel = new LoginModel();
  private apiUrl = environment.ApiUrl;
  public headers_object = new HttpHeaders();

  setLoginDetials(LoginDetails) {      
      this.LoginDetails = LoginDetails;
   } 
   getLoginDetials() {  
     return this.LoginDetails;  
   }  
  constructor(private _http: HttpClient, private _router: Router) {

  }

  // call the API and get the response from the API
  public validateLoginUser(loginmodel: LoginModel,GenerateOTP) {
      debugger;
      localStorage.clear();
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      
      let method="AuthonticateUser";//"A";
      if(GenerateOTP==1){
          method="GenerateOTP";
      }
      
      debugger;
      return this._http.get<any>(environment.ApiUrl + "/AIUserAuthenticate/"+method+"?userName=" + loginmodel.Username + "&password=" + loginmodel.Password+ "&OTP=" + loginmodel.OTP, { headers: headers });
        
  }

  // on logout click , below service get called 
  _logoutUser() {      
      localStorage.clear();    
     // location.reload(true);    //used to refresh the page so that service will intialized
      //location.reload();

      if(window.location.href.indexOf( environment.AirIndiaURL)>-1   )
      {
          this._router.navigate(['/ailogin']);     
      }

            
  }
  _changePassword(loginModelDetails:LoginModel,key) {
      return this._http.post(environment.ApiUrl + "/AIUserAuthenticate/ChangePassword?EmpCode=" + loginModelDetails.Username + "&OldPass=" + loginModelDetails.Password + '&NewPass=' + loginModelDetails.newPassword+'&key='+key +'&OTP='+loginModelDetails.OTP, '');    
    }


    /*CheckMobileAccess() {
      return this._http.get(environment.ApiUrl + "/UserAuthonticate/CheckMobileAccess");    
    }*/

  //to decrypt EmployeeCode
  /*_getEncryptedEmpCode(ID: string) {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
     return this._http.get(environment.ApiUrl + "/UserAuthonticate/DecryptEmployeeCode?Code=" + ID, { headers: headers });
     
    }*/

    _getEncryptedEmailID(ID: string) {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
      //return this._http.get(environment.ApiUrl + "/UserAuthonticate/DecryptEmployeeCode?Code=" + ID, { headers: headers });
      return this._http.get(environment.ApiUrl + "/AIUserAuthenticate/DecryptEmailID?Code=" + ID, { headers: headers });
    }
  

  private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
      } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError('Something bad happened; please try again later.');
  };



  _forgotPassword(EmailID) {
     
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
      return this._http.get(environment.ApiUrl + "/AIUserDetails/UpdateUserOTP?EmailID=" + EmailID);

 
  }

  _resetUserPassword(EmailID,OTP,NewPassword,ConfirmPassword) {
     
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
      return this._http.get(environment.ApiUrl + "/AIUserDetails/ResetPassword?EmailID=" + EmailID +"&OTP="+OTP+"&NewPassword="+NewPassword+"&ConfirmPassword="+ConfirmPassword);

 
  }

  _changePasswordAfterLogin(userid,oldPwd, newPwd) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    console.info("AIUserDetails");
    debugger;
    return this._http.post(environment.ApiUrl + "/AIUserDetails/ChangeUserPasswordAfterLogin?UserID=" + userid+ "&OldPass=" + oldPwd + '&NewPass=' + newPwd, '', { headers: headers });

  }

}