import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from './service/login-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'VisaWebApp';

  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(private _loginService:LoginService) {
   
    this.setTimeout();
    this.userInactive.subscribe(() => {
     /*  if(environment.ApiUrl.indexOf('localhost') == -1){ */
        this._loginService._logoutUser();
     // }
    });
    
    let favicon="assets/images/favicon.png";
    let styleName='assets/css/mainstyle.css';
    //console.info(environment.AirIndiaURL)
    //console.info(window.location.href.indexOf(environment.AirIndiaURL))
    //console.info(window.location.href.indexOf('register-me'))
    // || window.location.href.indexOf('register-me')>-1
    if(window.location.href.indexOf( environment.AirIndiaURL)>-1  || window.location.href.indexOf( "ailogin")>-1 || window.location.href.indexOf( "register-me")>-1   )
    {
      styleName='assets/css/aimainstyle.css?k=2';
      favicon="assets/aiimages/favicon.ico";
    }
    
        const head =document.getElementsByTagName('head')[0];
        console.log(head);
        let themeLink = document.getElementById(
          'client-theme'
        ) as HTMLLinkElement;
        if (themeLink) {
          themeLink.href = styleName;
        } else {
          const style = document.createElement('link');
          style.id = 'client-theme';
          style.rel = 'stylesheet';
          style.href = `${styleName}`;
          head.appendChild(style);


         

        }


        let iconLink = document.getElementById(
          'shortcuticon'
        ) as HTMLLinkElement;
        if (iconLink) {
          iconLink.href = favicon;
        } else {
          const shortcuticon = document.createElement('link');
          shortcuticon.id = 'shortcuticon';
          shortcuticon.rel = 'shortcut icon';
          shortcuticon.type = 'image/x-icon';
          shortcuticon.href = `${favicon}`;
          head.appendChild(shortcuticon);


          const shortcutmeta = document.createElement('meta');
          shortcutmeta.id = 'shortcutmeta';          
          shortcutmeta.setAttribute("property", "og:image");
          //shortcutmeta.type = 'image/x-icon';
          shortcutmeta.content = `${favicon}`;
          head.appendChild(shortcutmeta);
        }


      

        if(window.location.href.indexOf( environment.AirIndiaURL)>-1  || window.location.href.indexOf( "ailogin")>-1 || window.location.href.indexOf( "register-me")>-1   )
          {
            var noscript = document.createElement('noscript')
var iframe = document.createElement('iframe')
iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-58FGLQFC")
iframe.setAttribute("height", "0")
iframe.setAttribute("width", "0")
iframe.setAttribute("style", "display:none;visibility:hidden")
noscript.appendChild(iframe)
//document.body.appendChild(noscript);
document.body.prepend(noscript)
          }
          else{
            var noscript = document.createElement('noscript')
            var iframe = document.createElement('iframe')
            iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-TC53NNP6")
            iframe.setAttribute("height", "0")
            iframe.setAttribute("width", "0")
            iframe.setAttribute("style", "display:none;visibility:hidden")
            noscript.appendChild(iframe)
           // document.body.appendChild(noscript);
            document.body.prepend(noscript)

          }

       
      
    //console.info(head)
    


   }

   setTimeout() {
    this.userActivity = setTimeout(() => {
        this.userInactive.next(undefined);
    },1800000); // 30 mins
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:mousemove', ['$event']) refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}