 // This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: true,
/* SaltKey:'RIYAB2BVISA1650998748TST1ZSP8SRMP7SAPTW2W5Z4ZW46VDBL24LND638I0OIH3IJTKQJJBFMJ1UF4V45QFUT6RRX6NVNNV1I1S332',

  RPGReturnURL:'https://localhost:5002/#/branchExecutive/B2bRPGResponse',
  AIRRPGReturnURL:'https://localhost:5002/#/AI/B2bRPGResponse',
  RPGSiteJSURL:'https://rpguat.riya.travel/js/site.js',
  ApiUrl: 'https://localhost:44315/api',
  AirIndiaURL: 'AI' ,
  VisaCMSURL: 'https://visacms.riya.travel/',
   */
 //ApiUrl: 'http://localhost:50508/api'

 
   //ApiUrl : 'http://114.79.148.77:83/api' --prod
  // ApiUrl : 'https://visatrackerapi.oneriya.com/api' 
  /* Parikshan
  ApiUrl: 'http://visauatapi.parikshan.net/api',
 SaltKey:'RIYAB2BVISA1650998748TST1ZSP8SRMP7SAPTW2W5Z4ZW46VDBL24LND638I0OIH3IJTKQJJBFMJ1UF4V45QFUT6RRX6NVNNV1I1S332',
 
 RPGReturnURL:'http://visauat.parikshan.net/#/branchExecutive/B2bRPGResponse',
 AIRRPGReturnURL:'http://airvisab2b.parikshan.net/#/AI/B2bRPGResponse',
 RPGSiteJSURL:'https://rpguat.riya.travel/js/site.js',
 AirIndiaURL: 'airvisab2b.parikshan.net' ,
VisaCMSURL: 'https://visacms.riya.travel/',
  */


/*new Parikshan
ApiUrl: 'https://visab2bapitest.riyavisaservices.in/api',
SaltKey:'RIYAB2BVISA1650998748TST1ZSP8SRMP7SAPTW2W5Z4ZW46VDBL24LND638I0OIH3IJTKQJJBFMJ1UF4V45QFUT6RRX6NVNNV1I1S332',

RPGReturnURL:'https://b2btest.riyavisaservices.in/#/branchExecutive/B2bRPGResponse',
AIRRPGReturnURL:'https://processmyvisatest.processmyvisa.in/#/AI/B2bRPGResponse',
RPGSiteJSURL:'https://rpguat.riya.travel/js/site.js',
AirIndiaURL: 'processmyvisatest.processmyvisa.in' ,
VisaCMSURL: 'https://visacms.riya.travel/',
 */

/*Live
*/
ApiUrl: 'https://visab2bapi.riyavisaservices.in/api',
 SaltKey:'RIYA2023092423B2BVISU4MC3Y7J8WCNNO4JCJYCDYRG0UGK94P6OGL29UYDVGUZ82045NLC7ER6MGMJSXJEHG9GCBJSUUR6D1H7B32',
 
 //RPGReturnURL:'https://riyavisaservices.in/branchExecutive/B2bRPGResponse',
 RPGReturnURL:'https://riyavisaservices.in/#/branchExecutive/B2bRPGResponse',
 AIRRPGReturnURL:'https://aix.processmyvisa.in/#/branchExecutive/B2bRPGResponse',
 RPGSiteJSURL:'https://RPG.riya.travel/js/site.js',
AirIndiaURL: 'aix.processmyvisa.in' ,
VisaCMSURL: 'https://visacms.riya.travel/',



};


export const infocontent = {
  nearestbranch: 'Choose - Nearest Visa Service branch of the service provider from your residence or office',
 country:'The destination country for which Visa needs to be applied e.g. Singapore, Malaysia, UAE',
  visatype:'Purpose or reason of travel to destination country e.g. Tourism, Business, Transit, etc.',
  traveldate:'Departure Date of travel from India',
  Entries:'Number of times you are expected to enter destination country',
  givenname: 'First name as mentioned on biographic page',
  surname: 'Surname as on passport',
  passport:'Alphanumeric code typically comprised of a series of letters and numbers',
passportexpiry:'Expiry date is mentioned on biographic page',
additionalinfo:'Any information or details that you wish to provide that will assist us in processing your visa',
scanpassport:'Passport biographic page that contains your personal details such as name, gender, date of birth, etc.',
servicegst:'Goods & Service tax as applicable'

}

/*

export const infocontent = {
  nearestbranch: 'Nearest Servicing Branch',
 country:'Visiting Country',
  visatype:'Visit Purpose',
  traveldate:'Tentative Travel Date',
  givenname: 'Given Name on the Passport',
  surname: 'Surname as on passport',
  passport:'Valid passport number',
passportexpiry:'Passport expiry as on passport',
additionalinfo:'Any additional comments',
scanpassport:'Scan the First Page of the Passport to Auto-fill the Passenger Information',
servicegst:'18% of service charge'

}*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
