import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AiloginComponent } from './airindia/ailogin/ailogin.component';
import { HomeComponent } from './home/home.component';
import { NewVisaComponent } from './new-visa/new-visa.component';
import { PendingVisaComponent } from './pending-visa/pending-visa.component';
import { UserManagementComponent } from './creations/user-management/user-management.component';
import { BranchReportsComponent } from './creations/branch-reports/branch-reports.component';
import { ReportsComponent } from './creations/reports/reports.component';
// import {VisaFeesComponent } from './creations/visa-fees/visa-fees.component';
import { BranchManagementComponent } from './creations/branch-management/branch-management.component';
//import { AddVisaApplicationComponent } from './creations/add-visa-application/add-visa-application.component';
import { SubmittedAppComponent } from './submitted-app/submitted-app.component';
import { ClosedAPPComponent } from './closed-app/closed-app.component';
import { PageNotFoundComponent } from './global/page-not-found/page-not-found.component';
import { FeeMasterComponent } from './creations/fee-master/fee-master.component';

import { AddAppComponent } from './applications/add-app/add-app.component';
import { EditAppComponent } from './applications/edit-app/edit-app.component';

import { DubaiAddAppComponent } from './applications/dubai-add-app/dubai-add-app.component';
import { B2bViewVisaComponent } from './register/b2b-view-visa/b2b-view-visa.component';
import { ViewlistingAppComponent } from './applications/viewlisting-app/viewlisting-app.component';
import { ListAppComponent } from './applications/list-app/list-app.component';
import { AiListAppComponent } from './applications/ai-list-app/ai-list-app.component';

import { VisaOfficeComponent } from './creations/visa-office/visa-office.component';
import { VisatypeComponent } from './creations/Visatype-master/Visatype.component';
import { MiscFeesComponent } from './creations/misc-fees/misc-fees.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { ChangePasswordComponent } from './creations/change-password/change-password.component';
import { VisaProcessingMasterComponent } from './creations/visa-processing-master/visa-processing-master.component';
import { CountryMasterComponent } from './creations/country-master/country-master.component';
import { AdminAuthGuardService } from './AuthGuard/AdminAuthGuarad.service';
import { BranchExecutiveAuthGuardService } from './AuthGuard/BranchExecutiveAuthGuard.service';
import { BranchManagerAuthGuardService } from './AuthGuard/BranchManagerAuthGuard.service';
import { CollectionUserAuthGuardService } from './AuthGuard/CollectionUserAuthGuard.service';
import { DubaiManagerAuthGuardService } from './AuthGuard/DubaiManagerAuthGuard.service';
import { DubaiExecutiveAuthGuardService } from './AuthGuard/DubaiExecutiveAuthGuard.service';
//import { VisaprocessingoptionsComponent } from './creations/visaprocessingoptions/visaprocessingoptions.component';
import { ServicechargeMasterComponent } from './creations/servicecharge-master/servicecharge-master.component';
import { DummyComponent } from './creations/Dummy/dummy.component';
import { DeliveryPartnersComponent } from './creations/delivery-partners/delivery-partners.component';
import { ResetDefaultPasswordComponent } from './reset-default-password/reset-default-password.component';
import { AiResetPasswordComponent } from './airindia/ai-reset-password/ai-reset-password.component';
//import { BulkUploadComponent } from './creations/bulk-upload/bulk-upload.component';
import { ApprovalComponent } from './creations/approval/approval.component';
import { TaxinvoiceComponent } from './creations/taxinvoice/taxinvoice.component';
import { BranchIpComponent } from './creations/branch-ip/branch-ip.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientFormComponent } from './clients/client-form.component';
import { ERPPushStatusComponent } from './creations/erp-push-status/erp-push-status.component';
import { VFSVASFeesComponent } from './creations/vfs-vas-fees/vfs-vas-fees.component';
import { AccountsUserAuthGaurd } from './AuthGuard/AccountsUserAuthGaurd';
/*import { DubaivisaApplicationComponent} from './DubaiVisa/dubaivisa-application/dubaivisa-application.component';
import { EditdubaivisaApplicationComponent } from './DubaiVisa/editdubaivisa-application/editdubaivisa-application.component';

import { VisaInsuranceComponent } from './DubaiVisa/VisaInsurance/VisaInsurance.component';
import { VisaCopyComponent } from './DubaiVisa/VisaCopy/VisaCopy.component';
import { VisaBulkPostComponent } from './DubaiVisa/VisaBulkPost/VisaBulkPost.component';*/
import { QuotaComponent } from './RiyaQuota/Quota/Quota.component';
import { CheckPNRComponent } from './Visa/CheckPNR/CheckPNR.component';
import { DubaiVisaGuard } from './AuthGuard/dubai-visa.guard';
import { PNRReportAttributesComponent } from './creations/reports/pnrreport-attributes/pnrreport-attributes.component';
import { SettingComponent } from './Setting/setting/setting.component';
/*import { CashierVisaapplicationComponent } from './Cashier/cashier-visaapplication/cashier-visaapplication.component';
import { PaymentVoucherComponent } from './Cashier/payment-voucher/payment-voucher.component';
import { OutStandingComponent } from './Cashier/out-standing/out-standing.component';
import { CreditControleExcelUploadComponent } from './CreditControl/credit-controle-excel-upload/credit-controle-excel-upload.component';
*/
import { InvoiceReportComponent } from './invoice/invoice-report/invoice-report.component';
import { VisaFeesAutomationComponent } from './VisaFeesAutomation/visa_fees_automation.component';
import { CashierGuard } from './AuthGuard/cashier.guard';
import { CreditControlGuard } from './AuthGuard/credit-control.guard';
//import { AddB2bApplicationComponent } from './creations/add-b2b-application/add-b2b-application.component';
//import { EditB2bVisaComponent } from './creations/edit-b2b-visa/edit-b2b-visa.component';
import { BranchmanagementService } from './service/servicecall.service';
import { B2bAppSuccessComponent } from './register/b2b-app-success/b2b-app-success.component';
import { B2bpaymentComponent } from './register/b2bpayment/b2bpayment.component';
import { OktbpaymentComponent } from './register/oktbpayment/oktbpayment.component';
import { B2bRPGResponseComponent } from './register/b2b-rpgresponse/b2b-rpgresponse.component';
import { OktbRpgresponseComponent } from './register/oktb-rpgresponse/oktb-rpgresponse.component';

import { ViewAppComponent } from './applications/view-app/view-app.component'; 
import { ViewAppOktbComponent} from './applications/view-app-oktb/view-app-oktb.component'; 
import { ViewApprefComponent} from './applications/view-appref/view-appref.component'; 
import { ViewDubaivisaComponent } from './applications/view-dubaivisa/view-dubaivisa.component';
import { AgentRegComponent } from './register/agent-reg/agent-reg.component'; 
import { AiRegistrationComponent } from './airindia/ai-registration/ai-registration.component'; 
import { ViewAgentProfileComponent } from './profile/view-agent-profile/view-agent-profile.component';
import { ListAppRefComponent } from './applications/list-app-ref/list-app-ref.component';
import { AiprofileComponent } from './airindia/aiprofile/aiprofile.component';

export const routes: Routes = [
  { path: 'reset-default-password', component: ResetDefaultPasswordComponent},
  { path: 'registration', component: AgentRegComponent },
  { path: 'register-me', component: AiRegistrationComponent },
  { path: 'ai-reset-password', component: AiResetPasswordComponent},
  {
    path: 'Admin', component: HomeComponent,
    children: [
      { path: 'VisaApplications/:id', component: NewVisaComponent, canActivate: [AdminAuthGuardService] },
      { path: 'Clients', component: ClientsComponent, canActivate: [AdminAuthGuardService] },
      { path: 'ClientForm', component: ClientFormComponent, canActivate: [AdminAuthGuardService] },
      { path: 'Pending', component: PendingVisaComponent, canActivate: [AdminAuthGuardService] },
      { path: 'UserMaster', component: UserManagementComponent, canActivate: [AdminAuthGuardService] },
      { path: 'BranchMaster', component: BranchManagementComponent, canActivate: [AdminAuthGuardService] },
      { path: 'branchReport', component: BranchReportsComponent, canActivate: [AdminAuthGuardService] },
      // { path: 'report', component: ReportsComponent, canActivate: [AdminAuthGuardService] },
      { path: 'visaFeesMaster', component: FeeMasterComponent, canActivate: [AdminAuthGuardService] },
      //{ path: 'addVisaApp', component: AddVisaApplicationComponent, canActivate: [AdminAuthGuardService] },
      { path: 'Submitted', component: SubmittedAppComponent, canActivate: [AdminAuthGuardService] },
      { path: 'Closed', component: ClosedAPPComponent, canActivate: [AdminAuthGuardService] },
      //{ path: 'addVisaApplication', component: AddVisaApplicationComponent, canActivate: [AdminAuthGuardService] },
      { path: 'addApplication', component: AddAppComponent, canActivate: [AdminAuthGuardService] },
      { path: 'editApplication', component: EditAppComponent, canActivate: [AdminAuthGuardService] },
      

     
      { path: 'B2bAppSuccess', component: B2bAppSuccessComponent, canActivate: [AdminAuthGuardService]},
      { path: 'B2BPayment', component: B2bpaymentComponent, canActivate: [AdminAuthGuardService]},    
      { path: 'B2bRPGResponse', component: B2bRPGResponseComponent, canActivate: [AdminAuthGuardService]},
      { path: 'B2bViewVisa', component: B2bViewVisaComponent, canActivate: [AdminAuthGuardService]},
      { path: 'Visalist', component: ListAppComponent, canActivate: [AdminAuthGuardService]},
      { path: 'VisalistRef', component: ListAppRefComponent, canActivate: [AdminAuthGuardService]},
      { path: 'ViewVisa', component: ViewAppComponent, canActivate: [AdminAuthGuardService]},
      { path: 'ViewVisaRef', component: ViewApprefComponent, canActivate: [AdminAuthGuardService]},
      
      { path: 'OktbAppSuccess', component: B2bAppSuccessComponent, canActivate: [AdminAuthGuardService]},
      { path: 'OktbPayment', component: OktbpaymentComponent, canActivate: [AdminAuthGuardService]},    
      { path: 'OktbRPGResponse', component: OktbRpgresponseComponent, canActivate: [AdminAuthGuardService]},
      { path: 'ViewVisaOKTB', component: ViewAppOktbComponent, canActivate: [AdminAuthGuardService]},
      
      
      
      //{ path: 'bulkUpload', component:BulkUploadComponent, canActivate:[AdminAuthGuardService]},
      //{ path: 'updateVisa', component: UpdateVisaComponent, canActivate: [AdminAuthGuardService] },
      { path: 'VendorMaster', component: VisaOfficeComponent, canActivate: [AdminAuthGuardService] },
     // { path: 'editVisaApplication', component: EditVisaComponent, canActivate: [AdminAuthGuardService] },
      { path: 'CountryMaster', component: CountryMasterComponent, canActivate: [AdminAuthGuardService] },
      { path: 'VisatypeMaster', component: VisatypeComponent, canActivate: [AdminAuthGuardService] },
      { path: 'MiscfeesMaster', component: MiscFeesComponent, canActivate: [AdminAuthGuardService] },
      { path: 'VFSVASfeesMaster', component: VFSVASFeesComponent, canActivate: [AdminAuthGuardService] },
      { path: 'BranchIp', component: BranchIpComponent, canActivate: [AdminAuthGuardService] },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AdminAuthGuardService] },
      //{ path: 'updatevisa', component: UpdateVisaComponent, canActivate: [AdminAuthGuardService] },
      { path: 'globalsearch', component: GlobalSearchComponent, canActivate: [AdminAuthGuardService] },
      // { path: 'miscFees', component: MiscFeesComponent, canActivate: [AdminAuthGuardService] },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AdminAuthGuardService] },
      { path: 'VisaProcessingMaster', component: VisaProcessingMasterComponent, canActivate: [AdminAuthGuardService] },
      //{ path: 'VisaOptionsMaster', component: VisaprocessingoptionsComponent, canActivate: [AdminAuthGuardService] },
      { path: 'ServicechargeMaster', component: ServicechargeMasterComponent, canActivate: [AdminAuthGuardService] },
      { path: 'Dummy', component: DummyComponent, canActivate: [AdminAuthGuardService] },
      { path: 'DeliveryPartners', component: DeliveryPartnersComponent, canActivate: [AdminAuthGuardService] },
      { path: 'reports', component: ReportsComponent, canActivate: [AdminAuthGuardService] },
      { path: 'taxinvoice', component: TaxinvoiceComponent, canActivate: [AdminAuthGuardService] },
      { path: 'Approval', component: ApprovalComponent, canActivate: [AdminAuthGuardService]},
      { path: 'ERPPushCheck', component: ERPPushStatusComponent, canActivate: [AdminAuthGuardService]},
     
  
      { path: 'Quota', component: QuotaComponent ,canActivate:[DubaiVisaGuard]},//JD 27.01.2022
      { path: 'CheckPNR', component: CheckPNRComponent ,canActivate:[DubaiVisaGuard]},//JD 09.02.2022
      { path: 'PNRreports', component: PNRReportAttributesComponent, canActivate:[AdminAuthGuardService] },
      { path: 'Setting', component: SettingComponent, canActivate: [AdminAuthGuardService]},
     
     
      { path: '**', component: PageNotFoundComponent }       
    ]
  },
  {
    path: 'branchExecutive', component: HomeComponent,
    //path: '', component: HomeComponent,
    children: [
      { path: 'VisaApplications/:id', component: NewVisaComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'Pending', component: PendingVisaComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'Submitted', component: SubmittedAppComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'Closed', component: ClosedAPPComponent, canActivate: [BranchExecutiveAuthGuardService] },
      //{ path: 'addVisaApplication', component: AddVisaApplicationComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'addApplication', component: AddAppComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'editApplication', component: EditAppComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'dubaiaddApplication', component: DubaiAddAppComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'dubaiviewApplication', component: ViewDubaivisaComponent, canActivate: [BranchExecutiveAuthGuardService] },
      
      { path: 'B2bViewVisa', component: B2bViewVisaComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'Visalist', component: ListAppComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'VisalistRef', component: ListAppRefComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'ViewVisa', component: ViewAppComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'ViewVisaRef', component: ViewApprefComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'B2bAppSuccess', component: B2bAppSuccessComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'B2BPayment', component: B2bpaymentComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'B2bRPGResponse', component: B2bRPGResponseComponent, canActivate: [BranchExecutiveAuthGuardService]},
      //{ path: 'bulkUpload', component:BulkUploadComponent, canActivate:[BranchExecutiveAuthGuardService]},
      //{ path: 'editVisaApplication', component: EditVisaComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'globalsearch', component: GlobalSearchComponent, canActivate: [BranchExecutiveAuthGuardService] },
      //{ path: 'updatevisa', component: UpdateVisaComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'Dummy', component: DummyComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'taxinvoice', component: TaxinvoiceComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [BranchExecutiveAuthGuardService] }, { path: 'Dummy', component: DummyComponent, canActivate: [AdminAuthGuardService] },
      { path: 'PNRreports', component: PNRReportAttributesComponent, canActivate:[AdminAuthGuardService] },

      { path: 'agentprofile', component: ViewAgentProfileComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'ViewVisaOKTB', component: ViewAppOktbComponent, canActivate: [BranchExecutiveAuthGuardService]},
        
      { path: 'OktbAppSuccess', component: B2bAppSuccessComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'OktbPayment', component: OktbpaymentComponent, canActivate: [BranchExecutiveAuthGuardService]},    
      { path: 'OktbRPGResponse', component: OktbRpgresponseComponent, canActivate: [BranchExecutiveAuthGuardService]},
    
      { path: '**', component: PageNotFoundComponent }
    ]
  },
  {
    path: 'branchManager', component: HomeComponent,
    children: [
      { path: 'VisaApplications/:id', component: NewVisaComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'Pending', component: PendingVisaComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'Submitted', component: SubmittedAppComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'Closed', component: ClosedAPPComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'UserMaster', component: UserManagementComponent, canActivate: [BranchManagerAuthGuardService] },
     // { path: 'addVisaApplication', component: AddVisaApplicationComponent, canActivate: [BranchManagerAuthGuardService] },
      //{ path: 'bulkUpload', component:BulkUploadComponent, canActivate:[BranchManagerAuthGuardService]},
      //{ path: 'editVisaApplication', component: EditVisaComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'globalsearch', component: GlobalSearchComponent, canActivate: [BranchManagerAuthGuardService] },
     // { path: 'updatevisa', component: UpdateVisaComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'Dummy', component: DummyComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'taxinvoice', component: TaxinvoiceComponent, canActivate: [BranchManagerAuthGuardService] },
      { path: 'reports', component: ReportsComponent, canActivate: [BranchManagerAuthGuardService] },
      //{ path: 'DubaiVisaApplication', component: DubaivisaApplicationComponent , canActivate: [BranchManagerAuthGuardService]}, 
      //{ path: 'editdubaiVisaApplication', component: EditdubaivisaApplicationComponent , canActivate: [BranchManagerAuthGuardService]},
      //{ path: 'ViewdubaiVisaApplication', component: ViewDubaivisaComponent,canActivate:[BranchExecutiveAuthGuardService]},
      { path: 'PNRreports', component: PNRReportAttributesComponent, canActivate:[AdminAuthGuardService] },
      { path: '**', component: PageNotFoundComponent }
    ]
  },
  {
    path: 'AI', component: HomeComponent,
    children: [

      { path: 'addApplication', component: AddAppComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'editApplication', component: EditAppComponent, canActivate: [BranchExecutiveAuthGuardService] },
      { path: 'ViewVisa', component: ViewAppComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'ViewVisaOKTB', component: ViewAppOktbComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'ViewVisaRef', component: ViewApprefComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'B2bAppSuccess', component: B2bAppSuccessComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'B2BPayment', component: B2bpaymentComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'B2bRPGResponse', component: B2bRPGResponseComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'Visalist', component: AiListAppComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'agentprofile', component: AiprofileComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'ViewVisaOKTB', component: ViewAppOktbComponent, canActivate: [BranchExecutiveAuthGuardService]},
        
      { path: 'OktbAppSuccess', component: B2bAppSuccessComponent, canActivate: [BranchExecutiveAuthGuardService]},
      { path: 'OktbPayment', component: OktbpaymentComponent, canActivate: [BranchExecutiveAuthGuardService]},    
      { path: 'OktbRPGResponse', component: OktbRpgresponseComponent, canActivate: [BranchExecutiveAuthGuardService]},
    
    ]
  },
 
 
 { path: '', component: LoginComponent, pathMatch: 'full' },
 { path: 'ailogin', component: AiloginComponent, pathMatch: 'full' },

 //{ path: '', component: B2bVisaComponent, pathMatch: 'full' },
 //{ path: 'B2bAppSuccess', component: B2bAppSuccessComponent, pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
 

];
const config: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  enableTracing: false  // <-- debugging purposes only
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule { }
