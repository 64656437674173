import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, NgModel } from '@angular/forms';
import { DatePipe, PathLocationStrategy } from '@angular/common';
import {APP_BASE_HREF} from '@angular/common';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
// import { NgProgressRouterModule } from '@ngx-progressbar/router';
//import { TimepickerModule } from 'ngx-bootstrap/timepicker';
// import { CustomFormsModule } from 'ngx-custom-validators';
// import { FontAwesomeModule } from '@fortawesome/fontawesome-free';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { VisaHomeComponent } from './visa-home/visa-home.component';
import { NewVisaComponent } from './new-visa/new-visa.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PendingVisaComponent } from './pending-visa/pending-visa.component';
import { UserManagementComponent } from './creations/user-management/user-management.component';
import { BranchManagementComponent } from './creations/branch-management/branch-management.component';
import { BranchReportsComponent } from './creations/branch-reports/branch-reports.component';
import { ReportsComponent } from './creations/reports/reports.component';
// import { VisaFeesComponent } from './creations/visa-fees/visa-fees.component';
//import { AddVisaApplicationComponent } from './creations/add-visa-application/add-visa-application.component';
//import { AddB2bApplicationComponent } from './creations/add-b2b-application/add-b2b-application.component';
import { SubmittedAppComponent } from './submitted-app/submitted-app.component';
import { ClosedAPPComponent } from './closed-app/closed-app.component';
import { VisatypeComponent } from './creations/Visatype-master/Visatype.component';
import { HeaderMenuComponent } from './global/header-menu/header-menu.component';
//import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
//import { UpdateVisaComponent } from './creations/update-visa/update-visa.component';
import { PageNotFoundComponent } from './global/page-not-found/page-not-found.component';
import { FeeMasterComponent } from './creations/fee-master/fee-master.component';
//import { EditVisaComponent } from './creations/edit-visa/edit-visa.component';
import { ApplicationcountService } from './service/CountService';
import { VisaOfficeComponent } from './creations/visa-office/visa-office.component';
import { MiscFeesComponent } from './creations/misc-fees/misc-fees.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { ChangePasswordComponent } from './creations/change-password/change-password.component';
//import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { VisaProcessingMasterComponent } from './creations/visa-processing-master/visa-processing-master.component';
import { CountryMasterComponent } from './creations/country-master/country-master.component';
//import { VisaprocessingoptionsComponent } from './creations/visaprocessingoptions/visaprocessingoptions.component';
import { ServicechargeMasterComponent } from './creations/servicecharge-master/servicecharge-master.component';
import { DummyComponent } from './creations/Dummy/dummy.component';
import { DeliveryPartnersComponent } from './creations/delivery-partners/delivery-partners.component';
import { ResetDefaultPasswordComponent } from './reset-default-password/reset-default-password.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptorService} from './service/http-config-interceptor.service';
//import { BulkUploadComponent } from './creations/bulk-upload/bulk-upload.component';
import { ApprovalComponent } from './creations/approval/approval.component';
import { TaxinvoiceComponent } from './creations/taxinvoice/taxinvoice.component';
//import { CreationsComponent } from './branch-ip/creations/creations.component';
import { BranchIpComponent } from './creations/branch-ip/branch-ip.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientFormComponent } from './clients/client-form.component';
import { ERPPushStatusComponent } from './creations/erp-push-status/erp-push-status.component';
import { VFSVASFeesComponent } from './creations/vfs-vas-fees/vfs-vas-fees.component';
import { VFSVASFeesService } from './service/vfs-vfs.service';
//import { DubaivisaApplicationComponent } from './DubaiVisa/dubaivisa-application/dubaivisa-application.component';
import { AdddubaivisaService } from  './service/adddubaivisa.service';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
/*import { EditdubaivisaApplicationComponent } from './DubaiVisa/editdubaivisa-application/editdubaivisa-application.component';

import { VisaInsuranceComponent } from './DubaiVisa/VisaInsurance/VisaInsurance.component';
import { VisaCopyComponent } from './DubaiVisa/VisaCopy/VisaCopy.component';
import { VisaBulkPostComponent } from './DubaiVisa/VisaBulkPost/VisaBulkPost.component';*/
import { QuotaComponent } from './RiyaQuota/Quota/Quota.component';
import { CheckPNRComponent } from './Visa/CheckPNR/CheckPNR.component';
import { DubaivisaupdateserviceService } from './service/dubaivisaupdateservice.service';
//import { AwesomeTooltipDirective } from './DubaiVisa/awesome-tooltip.directive';
import { Newappservice } from './service/Newapplication_Service';
import { OverlayModule } from '@angular/cdk/overlay';
//import { AwesomeTooltipuaeComponent } from './DubaiVisa/awesome-tooltipuae/awesome-tooltipuae.component';
import { PNRReportAttributesComponent } from './creations/reports/pnrreport-attributes/pnrreport-attributes.component';
import { SettingComponent } from './Setting/setting/setting.component';
import { SettingServiceService } from './service/setting-service.service';
//import { CashierVisaapplicationComponent } from './Cashier/cashier-visaapplication/cashier-visaapplication.component';
import { CashierCreditService } from './service/cashier-credit.service';
/*import { PaymentVoucherComponent } from './Cashier/payment-voucher/payment-voucher.component';
import { OutStandingComponent } from './Cashier/out-standing/out-standing.component';
import { CreditControleExcelUploadComponent } from './CreditControl/credit-controle-excel-upload/credit-controle-excel-upload.component';
*/import { InvoiceReportComponent } from './invoice/invoice-report/invoice-report.component';
import { VisaFeesAutomationComponent } from './VisaFeesAutomation/visa_fees_automation.component';
import{VisaFeesAutomationService} from'./service/visaFeesAutomation.service';
//import { EditB2bVisaComponent } from './creations/edit-b2b-visa/edit-b2b-visa.component';
import { B2bVisaComponent } from './register/b2b-visa/b2b-visa.component';
import { B2bAppSuccessComponent } from './register/b2b-app-success/b2b-app-success.component';
import { B2bpaymentComponent } from './register/b2bpayment/b2bpayment.component';
import { B2bRPGResponseComponent } from './register/b2b-rpgresponse/b2b-rpgresponse.component';
import { B2bViewVisaComponent } from './register/b2b-view-visa/b2b-view-visa.component';
//import { ViewDubaivisaComponent } from './DubaiVisa/view-dubaivisa/view-dubaivisa.component';
import { B2bVisalistComponent } from './register/b2b-visalist/b2b-visalist.component';
import { HomeComponent } from './home/home.component';
import { AddAppComponent } from './applications/add-app/add-app.component';
//import { ListAppComponent } from './applications/list-app/list-app.component';
import { ViewlistingAppComponent } from './applications/viewlisting-app/viewlisting-app.component';
import { ListAppComponent } from './applications/list-app/list-app.component';
///import { ViewlistingAppComponent } from './application/viewlisting-app/viewlisting-app.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ViewAppComponent } from './applications/view-app/view-app.component';
import { DubaiAddAppComponent } from './applications/dubai-add-app/dubai-add-app.component'; 
import { ViewDubaivisaComponent } from './applications/view-dubaivisa/view-dubaivisa.component';
import { AgentRegComponent } from './register/agent-reg/agent-reg.component';
import { ViewAgentProfileComponent } from './profile/view-agent-profile/view-agent-profile.component';
import { EditAppComponent } from './applications/edit-app/edit-app.component';
import { ViewApprefComponent } from './applications/view-appref/view-appref.component';
import { ListAppRefComponent } from './applications/list-app-ref/list-app-ref.component'; 
import { RouterModule } from '@angular/router';
import { AiRegistrationComponent } from './airindia/ai-registration/ai-registration.component';
import { AiListAppComponent } from './applications/ai-list-app/ai-list-app.component';
import { AiResetPasswordComponent } from './airindia/ai-reset-password/ai-reset-password.component';
import { AiloginComponent } from './airindia/ailogin/ailogin.component';
import { AiprofileComponent } from './airindia/aiprofile/aiprofile.component';
import { OktbpaymentComponent } from './register/oktbpayment/oktbpayment.component';
import { ViewAppOktbComponent } from './applications/view-app-oktb/view-app-oktb.component';
import { OktbRpgresponseComponent } from './register/oktb-rpgresponse/oktb-rpgresponse.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,B2bVisaComponent,
    VisaHomeComponent,
    NewVisaComponent,
    NavMenuComponent,
    HeaderMenuComponent,
    PendingVisaComponent,
    UserManagementComponent,
    BranchManagementComponent,
    BranchReportsComponent,
    ReportsComponent,
    //AddVisaApplicationComponent,
   // AddB2bApplicationComponent,
    SubmittedAppComponent,
    ClosedAPPComponent,
    //UpdateVisaComponent,
    PageNotFoundComponent,
    FeeMasterComponent,
    //EditVisaComponent,
    VisaOfficeComponent,
    MiscFeesComponent,
    ChangePasswordComponent,
    GlobalSearchComponent,
    GlobalSearchComponent,
    MiscFeesComponent,
    ChangePasswordComponent,
    VisaProcessingMasterComponent,
    VisatypeComponent,
    CountryMasterComponent,
    //VisaprocessingoptionsComponent,BulkUploadComponent,
    ServicechargeMasterComponent,
    DummyComponent,
    DeliveryPartnersComponent,
    ResetDefaultPasswordComponent,
    
    ApprovalComponent,
    TaxinvoiceComponent,
    //CreationsComponent,
    BranchIpComponent,
    ClientsComponent,
    ClientFormComponent,
    ERPPushStatusComponent,
    VFSVASFeesComponent,
    
    QuotaComponent,
    CheckPNRComponent,
    //AwesomeTooltipDirective,
    //AwesomeTooltipuaeComponent,
    PNRReportAttributesComponent,
    SettingComponent,
    /*CashierVisaapplicationComponent,
    PaymentVoucherComponent,
    OutStandingComponent,
    CreditControleExcelUploadComponent,*/
    InvoiceReportComponent,
    VisaFeesAutomationComponent,
    //AddB2bApplicationComponent,
    
    B2bVisaComponent,
    B2bAppSuccessComponent,
    B2bpaymentComponent,
    B2bRPGResponseComponent,
    B2bViewVisaComponent,
   
    B2bVisalistComponent,
    HomeComponent,
    AddAppComponent,
    ViewlistingAppComponent,ListAppComponent, ViewAppComponent, DubaiAddAppComponent,ViewDubaivisaComponent, AgentRegComponent, ViewAgentProfileComponent, EditAppComponent, ViewApprefComponent, ListAppRefComponent, AiRegistrationComponent, AiListAppComponent, AiResetPasswordComponent, AiloginComponent, AiprofileComponent, OktbpaymentComponent, ViewAppOktbComponent, OktbRpgresponseComponent
    //ViewlistingAppComponent,
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AutocompleteLibModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,NgxDatatableModule,

    // CustomFormsModule,
    CommonModule,
   // TooltipModule.forRoot(),
    //BsDatepickerModule.forRoot(),
    //TimepickerModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    OverlayModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgProgressModule.withConfig({
      spinner: false,
      color: "#de3d4e"
    })
    // NgProgressRouterModule
    // NgProgressHttpModule.withConfig({
    //   silentApis: ['http://114.79.148.77:83/api/StatusMaster/CountMaster']
    // })

    ,RouterModule.forRoot(routes, { useHash: false }) //make it false

  ],
  providers: [VFSVASFeesService,Newappservice,ApplicationcountService, DatePipe,AdddubaivisaService,DubaivisaupdateserviceService,SettingServiceService,CashierCreditService,VisaFeesAutomationService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptorService, multi: true},
  //  { provide: PathLocationStrategy},
    //{provide: APP_BASE_HREF, useValue: '/'},

   { provide: LocationStrategy, useClass: HashLocationStrategy}
],
    
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  


})
export class AppModule { }
